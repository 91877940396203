/* @media (min-width: 992px) { */
    .hero {
      /* height: 45vw; */
      padding-top: 5vw;
      padding-bottom: 5vw;
      width: 100vw;
      height: 45vw;
      top: 0;
      border: 0px Solid rgb(24, 136, 121);
    }

    .hero__text {
        /* max-width: 800px; */
        text-align: center;
        vertical-align: middle;
        margin-left: auto;  
        margin-right:auto;
        border: 0px Solid Yellow;
        margin-top: auto;
        margin-bottom: auto;
      }

    .hero__div {
      padding: 1vw;
      margin: 2vw;
    }  

  .set-bg {
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: top center;
    background-image: url(../media/header.png);
  }

  .titletext{
    color: #ffffff !important;
    font-family: 'VeryBerry';
    font-size: 5vw;
    
  }

  .timesnewromantext{
    color: #ffffff !important;
    font-family: 'Times New Roman';
    font-size: 3vw;
    
  }

  .headervignettes{
    color: #ffffff !important;
    font-family: '50123';
    font-size: 7vw;
    
  }

  @media (max-width: 320px) {
    .hero {
      padding-top: 5vw;
      padding-bottom: 5vw;
      width: 100%;
      height: 150px;
      top: 0;
      border: 0px Solid rgb(203, 6, 94);
  
    }
  }  
/* }

@media ((min-width: 401px) and (max-width: 991px)) {
  .hero {
    height: 45vw;
    padding-top: 10px;
    padding-bottom: 50px;
    top: 0;
    margin:0;
    width: 100%;
    border: 3px Solid rgb(156, 7, 74);

  }

  .hero__text {
      max-width: 680px;
      text-align: center;
      margin-left: auto;  
      margin-right: auto;
      border: 0px Solid Yellow;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .hero__div {
      padding: 2px;
      margin: 5px
    }  

  .set-bg {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top center;
    background-image: url(../media/header.png);
  }

  .titletext{
      color: #ffffff !important;
      font-family: 'VeryBerry';
      font-size: 3em;
      
    }
  
    .timesnewromantext{
      color: #ffffff !important;
      font-family: 'Times New Roman';
      font-size: 1em;
      
    }
    .headervignettes{
      color: #ffffff !important;
      font-family: '50123';
      font-size: 1em;
      
    }

}

@media (max-width: 400px) {
  .hero {
    height: 60vw;
    padding-top: 20px;
    padding-bottom: 100px;
    width:100vw;
    top: 0;
    border: 3px Solid rgb(43, 6, 205);
  }

  .hero__text {
      max-width: 400px;
      text-align: center;
      margin-left: auto;  
      margin-right: auto;
      border: 0px Solid Yellow;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .hero__div {
      padding: 0px;
      margin: 0px
    }  

  .set-bg {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    background-image: url(../media/header.png);
  }

  .titletext{
      color: #ffffff !important;
      font-family: 'VeryBerry';
      font-size: 2em;
      
    }
  
    .timesnewromantext{
      color: #ffffff !important;
      font-family: 'Times New Roman';
      font-size: .8em;
      
    }
    .headervignettes{
      color: #ffffff !important;
      font-family: '50123';
      font-size: 1em;
      
    }

}
  */