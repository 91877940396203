.gallery-main {
  margin-top: 20px;  
  width: '100%'; 
  display: block;
  position: relative; 
  padding-left:10px;
  padding-right:10px;
  background-color: #ffffff;
  padding-top: 2vw;
  border: 2px Solid Green;
}

.masonry-outer-container{
  margin-top: 50px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width:90%;
  margin-left:auto;
  margin-right:auto;
  /* border: 2px Solid Green; */
}

.masonry {
  display: grid;
  /* grid-template-rows: auto; */
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 0;
  width: 90%;
  max-width: 1200px;
  margin: 20px 0;
  /* border: 3px Solid Orange; */
}

.c{
  break-inside: avoid;
  margin: 0;
}

.masonry-item img {
  width: 100%;
  display: block;
  /* border: 3px Solid Orange; */
  /* border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

/* lightbox modal styles */
.modal-image-buttons{
  height: 30px;
  justify-content: center;
  align-items: center;
  border: 3px Solid Orange; 
}

.modal-image{
  max-height: 80vh;
  justify-content: center;
  align-items: center;
  margin-left:auto;
  margin-right:auto;
  position: relative;
  /* border: 3px Solid Orange;  */
}

.modal-outer-container{
  width:100%;
  border: 3px Solid green; 
}

 
  


/* lightbox modal styles 2 */

.modal-body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  background-color: #faf0ee;
  position: relative;
}

.image-container {
  position: relative;
  /* width: 800px; */
  height: 100%;
}

.center-image {
  display: block;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.arrow-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #e7a49b;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index:20;
}

.left-arrow {
  left: 0;
}

.right-arrow {
  right: 0;
}

.close-button {
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: #e7a49b;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 20px;
  z-index:20;
}

.arrow-button:hover, .close-button:hover {
  background-color: #e7a49b;
}



 /* image overlay for gallery images styles */

 .fa {
  display: inline-block;
  font-family: '50124';
  font-size: 5em;
  color: #faf0ee;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.c-content-isotope-gallery .c-content-isotope-item {
  height: auto;
  position: relative; }
  .c-content-isotope-gallery .c-content-isotope-item > .c-content-isotope-image-container {
    position: relative; }
    .c-content-isotope-gallery .c-content-isotope-item > .c-content-isotope-image-container > .c-content-isotope-image {
      width: 100%;
      height: auto; }
    .c-content-isotope-gallery .c-content-isotope-item > .c-content-isotope-image-container > .c-content-isotope-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #e7a49b;
      top: 0;
      left: 0;
      opacity: 0;
      webkit-transition: all 0.2s ease-in-out;
      -moz-transition: all 0.2s ease-in-out;
      -ms-transition: all 0.2s ease-in-out;
      -o-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out; }
      .c-content-isotope-gallery .c-content-isotope-item > .c-content-isotope-image-container > .c-content-isotope-overlay > .c-content-isotope-overlay-icon {
        color: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%); }
      .c-content-isotope-gallery .c-content-isotope-item > .c-content-isotope-image-container > .c-content-isotope-overlay:hover {
        opacity: 0.75;
        cursor: pointer; }

.c-content-isotope-gallery.c-opt-1 .c-content-isotope-item, .c-content-isotope-gallery.c-opt-4 .c-content-isotope-item, .c-content-isotope-gallery.c-opt-5 .c-content-isotope-item {
  width: 100%; }
  .c-content-isotope-gallery.c-opt-1 .c-content-isotope-item.c-item-size-double, .c-content-isotope-gallery.c-opt-4 .c-content-isotope-item.c-item-size-double, .c-content-isotope-gallery.c-opt-5 .c-content-isotope-item.c-item-size-double {
    width: 100%; }

    @media (max-width: 991px) {
      .c-content-isotope-gallery.c-opt-1 .c-content-isotope-item, .c-content-isotope-gallery.c-opt-4 .c-content-isotope-item, .c-content-isotope-gallery.c-opt-5 .c-content-isotope-item {
        width: 100%; }
        .c-content-isotope-gallery.c-opt-1 .c-content-isotope-item.c-item-size-double, .c-content-isotope-gallery.c-opt-4 .c-content-isotope-item.c-item-size-double, .c-content-isotope-gallery.c-opt-5 .c-content-isotope-item.c-item-size-double {
          width: 100%; }
      .c-content-isotope-gallery.c-opt-2 .c-content-isotope-item {
        width: 100%; }
      .c-content-isotope-gallery.c-opt-3 .c-content-isotope-item {
        width: 100%; } }
        
        .c-content-isotope-gallery .c-content-isotope-item {
          height: auto;
          position: relative; }
          .c-content-isotope-gallery .c-content-isotope-item > .c-content-isotope-image-container {
            position: relative; }
            .c-content-isotope-gallery .c-content-isotope-item > .c-content-isotope-image-container > .c-content-isotope-image {
              width: 100%;
              height: auto; }
            .c-content-isotope-gallery .c-content-isotope-item > .c-content-isotope-image-container > .c-content-isotope-overlay {
              position: absolute;
              width: 100%;
              height: 100%;
              background-color: #e7a49b;
              top: 0;
              left: 0;
              opacity: 0;
              webkit-transition: all 0.2s ease-in-out;
              -moz-transition: all 0.2s ease-in-out;
              -ms-transition: all 0.2s ease-in-out;
              -o-transition: all 0.2s ease-in-out;
              transition: all 0.2s ease-in-out; }
              .c-content-isotope-gallery .c-content-isotope-item > .c-content-isotope-image-container > .c-content-isotope-overlay > .c-content-isotope-overlay-icon {
                color: #fff;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateY(-50%) translateX(-50%); }
              .c-content-isotope-gallery .c-content-isotope-item > .c-content-isotope-image-container > .c-content-isotope-overlay:hover {
                opacity: 0.75;
                cursor: pointer; }       

                .c-content-isotope-gallery.c-opt-1 .c-content-isotope-item, .c-content-isotope-gallery.c-opt-4 .c-content-isotope-item, .c-content-isotope-gallery.c-opt-5 .c-content-isotope-item {
                  width: 100%; }
                  .c-content-isotope-gallery.c-opt-1 .c-content-isotope-item.c-item-size-double, .c-content-isotope-gallery.c-opt-4 .c-content-isotope-item.c-item-size-double, .c-content-isotope-gallery.c-opt-5 .c-content-isotope-item.c-item-size-double {
                    width: 100%; }
  
                @media (max-width: 991px) {
                  .c-content-isotope-gallery.c-opt-1 .c-content-isotope-item, .c-content-isotope-gallery.c-opt-4 .c-content-isotope-item, .c-content-isotope-gallery.c-opt-5 .c-content-isotope-item {
                    width: 100%; }
                    .c-content-isotope-gallery.c-opt-1 .c-content-isotope-item.c-item-size-double, .c-content-isotope-gallery.c-opt-4 .c-content-isotope-item.c-item-size-double, .c-content-isotope-gallery.c-opt-5 .c-content-isotope-item.c-item-size-double {
                      width: 100%; }
                  }
                
                .c-content-isotope-grid .c-content-isotope-item {
                  height: auto;
                  position: relative;
                  overflow: hidden; }
                  .c-content-isotope-grid .c-content-isotope-item > .c-content-isotope-image-container {
                    position: relative;
                    overflow: hidden; }
                    .c-content-isotope-grid .c-content-isotope-item > .c-content-isotope-image-container .c-content-isotope-image {
                      width: 100%;
                      height: auto;
                      webkit-transition: all 0.3s ease-in-out;
                      -moz-transition: all 0.3s ease-in-out;
                      -ms-transition: all 0.3s ease-in-out;
                      -o-transition: all 0.3s ease-in-out;
                      transition: all 0.3s ease-in-out; }
                    .c-content-isotope-grid .c-content-isotope-item > .c-content-isotope-image-container > .c-content-isotope-overlay {
                      position: absolute;
                      width: 100%;
                      height: 100%;
                      padding: 30px;
                      background-color: rgba(0, 0, 0, 0.7);
                      top: 0;
                      left: 0;
                      opacity: 0;
                      webkit-transition: all 0.3s ease-in-out;
                      -moz-transition: all 0.3s ease-in-out;
                      -ms-transition: all 0.3s ease-in-out;
                      -o-transition: all 0.3s ease-in-out;
                      transition: all 0.3s ease-in-out; }
                      .c-content-isotope-grid .c-content-isotope-item > .c-content-isotope-image-container > .c-content-isotope-overlay > .c-content-isotope-overlay-content {
                        text-align: center; }
                        .c-content-isotope-grid .c-content-isotope-item > .c-content-isotope-image-container > .c-content-isotope-overlay > .c-content-isotope-overlay-content > .c-content-isotope-overlay-title {
                          margin-top: 0;
                          font-size: 18px;
                          font-weight: 600; }
                        .c-content-isotope-grid .c-content-isotope-item > .c-content-isotope-image-container > .c-content-isotope-overlay > .c-content-isotope-overlay-content > .c-content-isotope-overlay-desc {
                          font-size: 14px; }
                        .c-content-isotope-grid .c-content-isotope-item > .c-content-isotope-image-container > .c-content-isotope-overlay > .c-content-isotope-overlay-content > .c-content-isotope-overlay-price {
                          font-size: 28px; }
                    .c-content-isotope-grid .c-content-isotope-item > .c-content-isotope-image-container:hover > .c-content-isotope-overlay {
                      opacity: 1; }
                
                .c-content-isotope-grid.c-opt-1 {
                  margin: -10px; }
                  .c-content-isotope-grid.c-opt-1 .c-content-isotope-item {
                    width: 100%;
                    padding: 0px; }
                    .c-content-isotope-grid.c-opt-1 .c-content-isotope-item.c-item-size-double {
                      width: 100%; }
                    .c-content-isotope-grid.c-opt-1 .c-content-isotope-item .c-content-isotope-image-container {
                      padding: 7px;
                      background-color: rgba(0, 0, 0, 0.1); }
                      .c-content-isotope-grid.c-opt-1 .c-content-isotope-item .c-content-isotope-image-container > .c-content-isotope-overlay {
                        width: auto;
                        height: auto;
                        left: 7px;
                        right: 7px;
                        top: auto;
                        bottom: -100%; }
                      .c-content-isotope-grid.c-opt-1 .c-content-isotope-item .c-content-isotope-image-container:hover > .c-content-isotope-overlay {
                        bottom: 7px; }
                              