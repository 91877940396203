.rsvp-header {
    margin-top: 5px;  
    margin-bottom: 10px;  
    padding-top: 10px;  
    padding-bottom: 20px;  
  }  

  .rsvp-main {
    margin-top: 8vw;  
    width: '100%'; 
    display:'inline';
    position: 'relative'; 
    border: 0px Solid Magenta;
    padding-left:10px;
    padding-right:10px;
}
.rsvp-outer {
    text-align: center;
    margin-top: 10vw;
    margin-left: auto;  
    margin-right:auto;
    margin-bottom: 1vw;
    background-color: #faf0ee;
    /* color:#e6bdb6; */
    color:#e7a49b;
    display: block;
    border-radius: 80vw;
    padding:1%;
    position: relative;
    width:70vw;
}

.rsvp-text {
    /* font-family: 'Times New Roman'; */
    font-size: 5vw;
    font-weight:500;
    margin: 5vw;
    padding:0px;
}