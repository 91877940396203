.page-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #e6bdb6; /* Change this to your desired header background color */
    color: #fff; /* Change this to your desired header text color */
    padding: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    transform: translateY(-100%);
    z-index: 10;
}

.page-header.visible {
    transform: translateY(0);
}


@media (min-width: 992px) {
 
}


@media (max-width: 991px) {

}

  