
.outer-container{
    border: 0px Solid Pink;
    width: 100%;
    padding-top:80px;
    margin-bottom:20px;
    padding-bottom:50px;
    display:inline-block;
    position:relative;
    height:100%;
}

.container2 {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index:10;
    border: 0px Solid Green;
  }

  .love-header{
    font-family: 'MichelElisha';
    font-size: 10vw;
    margin-top: 5vw;
    margin-bottom: 5vw; 
    color: #e6bdb6;
    margin-left:auto;
    margin-right:auto;
    text-align: center;
}
.firstDate{
    background-image: url(../media/first_date.jpg);
}
.relationship{
    background-image: url(../media/relationship.jpg);
}
.visitingNicoleinLA{
    background-image: url(../media/visiting_nicole_in_la.jpg);
}
.gotDog{
    background-image: url(../media/bella.png);
}
.engaged{
    background-image: url(../media/engaged.png);
}

@media (min-width: 992px) {
    .love-story-row1{
        width:90%;
        margin-top:50px;
        padding-bottom:0px;
        margin-left:auto;
        margin-right:auto;
        /* border: 1px Solid Green; */
        display:block;
       
    }
    .love-story-row{
        width:90%;
        margin-top:50px;
        padding-bottom:0px;
        margin-left:auto;
        margin-right:auto;
        /* border: 1px Solid Green; */
        display:block;
       
    }
    .divider {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        width: 3px;
        background-color:#e7a49b;
        transform: translateX(-50%);
        z-index: 0;
    }

    .left-cell{
        width:40%;
        /* border: 1px Solid Blue; */
        display: inline-flex;
        justify-content: flex-end;
    }
    .center-cell{
        width:20%;
        text-align: center;
        /* border: 1px Solid rgb(36, 53, 2); */
        display:inline-flex;
        position:relative;
    }
    .right-cell{
        width:40%;
        /* border: 1px Solid Red; */
        display:inline-flex;
    }
    .circle{
        border: Solid 5px #e7a49b;
        border-radius:50%;
        width:150px;
        height:150px;
        background-size:cover;
        background-position: center center;
        background-repeat: no-repeat;
        margin-left:auto;
        margin-right:auto;
        z-index: 10;
    }

    .frame-box{
        border: Solid 0px #676767;
        transform: rotate(-2deg);
        width: 300px;
        height: 150px;
        text-align: left;
       
        padding: 5px 20px 50px 5px;
        margin: 2px 10px 0px 10px; 
    }
    .frame-box1{
        border: Solid 1px #676767;
        transform: rotate(-2deg);
        width: 300px;
        height: 150px;
        text-align: left;
        margin-top:30px;

    }
    .frame-box2{
        border: Solid 1px #676767;
        transform: rotate(4deg);
        width: 300px;
        height: 150px;
        text-align: left;
       
    }

    .love-pink-header{
        color: #e7a49b;
        font-size: 1em;
    }

    .love-box-header{
        color: #676767;
        font-size: 1.5em;
        margin-top:10px;
    }

    .love-box-text{
        color: #676767;
        font-size: 1em;
        margin-top:10px;
    }

}


@media (max-width: 991px) {
    .outer-container{
        border: 0px Solid Red;
        width: 100%;
        padding-top:20px;
        margin-bottom:20px;
        padding-bottom:50px;
        display:inline-block;
        position:relative;
        height:100%;
    }

    .love-story-row1{
        width:100%;
        margin-top:0px;
        padding-bottom:0px;
        margin-left:auto;
        margin-right:auto;
        /* border: 1px Solid Green; */
        display:block;
        position:relative;
    }
    .love-story-row{
        width:100%;
        margin-top:50px;
        padding-bottom:0px;
        margin-left:auto;
        margin-right:auto;
        /* border: 1px Solid Green; */
        display:block;
        position:relative;
    }
    .divider {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 20%;
        width: 3px;
        background-color:#e7a49b;
        transform: translateX(-50%);
        z-index: -40;
      }

    .left-cell{
        width:60%;
        display: inline;
        float:right;
        position:absolute;
        top:25px;
        right:0px;
 
    }
    .center-cell{
        width:40%;
        text-align: center;
        /* border: 2px Solid rgb(197, 129, 3); */
        display:inline;
        float: left;
        z-index: 10;
    }
    .right-cell{
        width:60%;
        /* border: 1px Solid Red;
        background-color: aqua; */
        display:inline;
        float:right;
        position:absolute;
        top:20px;
        right:0px;
    }
    .circle{
        border: Solid 3px #e7a49b;
        border-radius:75px;
        width:100px;
        height:100px;
        background-size:cover;
        background-position: center center;
        background-repeat: no-repeat;
        margin-left:auto;
        margin-right:auto;
        z-index: 10;
        margin-top:50px;
    }

    .frame-box{
        transform: rotate(-2deg);
        width: 100%;
        min-height: 120px;  
        text-align: left;
       
        padding: 5px 20px 15px 5px;
        margin: 5px 10px 0px 5px; 
    }
    .frame-box1{
        border: Solid 1px #676767;
        transform: rotate(-2deg);
        width: 90%;
        /* height: 150px; */
        min-height: 120px;  
        text-align: left;
        margin-top:30px;

    }
    .frame-box2{
        border: Solid 1px #676767;
        transform: rotate(4deg);
        width: 100%;
        /* height: 150px; */
        min-height: 120px;  
        text-align: left;

    }

    .love-pink-header{
        color: #e7a49b;
        font-size: .8em;
    }

    .love-box-header{
        color: #676767;
        font-size: 1.2em;
        margin-top:10px;
    }

    .love-box-text{
        color: #676767;
        font-size: .9em;
        margin-top:5px;
    }

}


 

