@font-face {
    font-family: 'MichelElisha';
    src: url('fonts/MICHELELISHA.TTF') format('truetype');
}

@font-face {
    font-family: 'VeryBerry';
    src: url('fonts/VeryBerry Pro Regular.ttf') format('truetype');
}

@font-face {
    font-family: '50123';
    src: url('fonts/50123.otf') format('truetype');
}

@font-face {
    font-family: '50124';
    src: url('fonts/50124.otf') format('truetype');
}