.pt-main {
    margin-top: 20px;  
    width: '100%'; 
    display: block;
    position: relative; 
    padding-left:10px;
    padding-right:10px;
    background-color:#e6bdb6;
    padding-top: 2vw;
}

.pt-love-header{
    margin-top: 5vw;  
    margin-bottom: 5vw;  
    border: 0px Solid Orange;
    font-family: 'MichelElisha';
    font-size: 10vw;
    color: #ffffff;
    margin-left:auto;
    margin-right:auto;
    text-align: center;
}

.pt-body{
  border: 0px Solid Red;
  display: flex;
  flex-wrap: wrap; /* Ensure wrapping for smaller screens */
  justify-content: center; /* Center items horizontally */
  gap: 50px; /* Add space between divs */
  padding: 20px;
}

.pt-card{
    flex: 1 1 calc(50% - 20px); /* Flexible, takes 50% width minus gap for space */
    max-width: 45%; /* Limit max width */
    height: 60vw;
    background-color: #f9f9f9; /* Fallback background color */
    background-clip: border-box;
    border: 15px solid transparent; /* Creates space for the border image */
    /* background-image: url('border-image.png'), url('content-bg.png'); Specify border and content background */
    background-origin: border-box; /* Control areas of background images */
    background-repeat: no-repeat;
    background-size: cover, cover;
    text-align: center;
    line-height: 80vw;
    font-weight: bold;
    color: #333;
    box-sizing: border-box; /* Include padding and border in size */ 
}

.pt-ceremony-background{
    background-image: url(../media/ceremony.png);
}

.pt-reception-background{
    background-image: url(../media/reception.png);
}

.pt-white-card{
    background-color: #ffffff;
    height:100%;
    width:100%;
    padding-top: 50px;
    padding-bottom: 50px;
    position: inherit;
}

.pt-brideandgroom-icon{
    background-image: url(../media/bride-and-groom.png);
    width:15vw;
    height:15vw;
    background-repeat: no-repeat;
    background-size: contain;
    margin-left: auto;
    margin-right: auto; 
    margin-bottom: 10px; 
}

.pt-cake-icon{
    background-image: url(../media/cake.png);
    width:15vw;
    height:15vw;
    background-repeat: no-repeat;
    background-size: contain;
    margin-left: auto;
    margin-right: auto; 
    margin-bottom: 10px; 
}

.pt-card-header{
    font-size: 2.5em;
    font-weight: 500;
}

.pt-card-content{
    font-size: 1.2em;
    font-weight: 400;
    margin-left: auto;
    margin-right: auto; 
}

.pt-card-venuename{
    font-size: 1.2em;
    font-weight: Bold;
    margin-left: auto;
    margin-right: auto; 
}

.pt-map-button{
    background-color: #ffffff;
    border: 2px Solid #e7a49b;
    width:100px;
    height:40px;
    line-height:40px;
    text-decoration:none;
    color:#e7a49b;
    padding:20px 30px 20px 30px;
}
 
.pt-map-button:link {
    color: #e7a49b; 
    text-decoration: none; 
  }
  
  /* Set the visited link color to match */
  .pt-map-button:visited {
    color: #e7a49b; 
    text-decoration: none; 
  }
  
  /* Optional: Add hover and active states for styling */
  .pt-map-button:hover {
    color: #e6bdb6; 
    text-decoration: none; 
  }
  
  .pt-map-button:active {
    color: #e7a49b; 
    text-decoration: none; 
  }

 

/* Responsive adjustments */
@media (max-width: 991px) {
    .pt-card {
      flex: 1 1 100%; /* Take full width on smaller screens */
      max-width: 100%;
      height:100%;
    }

    .pt-card-header{
        font-size: 1.5em;
        font-weight: 500;
    }
    
    .pt-card-content{
        font-size: 12px;
        font-weight:400;
    }

  }