
/*-----------------------------------
countdown
-----------------------------*/
.countdown-main {
    width: '100%'; 
    display:'inline';
    position: 'relative'; 
    border: 0px Solid Magenta;
    padding-left:10px;
    padding-right:10px;
}
.countdown-outer {
    text-align: center;
    margin-top: 1vw;
    margin-left: auto;  
    margin-right:auto;
    margin-bottom: 1vw;
    background-color: #faf0ee;
    /* color:#e6bdb6; */
    color:#e7a49b;
    display: block;
    border-radius: 80vw;
    padding:1%;
    position: relative;
    width:80vw;
  }

.countdown-element{
    width:24%;
    display:inline-block;
}

.countdown-number{
    font-family: 'Times New Roman';
    font-size: 4vw;
    font-weight: bold;
    margin:0px;
    padding:0px;
}
.countdown-text{
    font-family: 'Times New Roman';
    font-size: 3vw;
    margin:0px;
    padding:0px;
}

/* @media (min-width: 992px) {
    .countdown-number{
        font-family: 'Times New Roman';
        font-size: 3vw;
        font-weight: bold;
        margin:0px;
        padding:0px;
    }
    .countdown-text{
        font-family: 'Times New Roman';
        font-size: 2vw;
        margin:0px;
        padding:0px;
    }
}


@media (max-width: 991px) {
    .countdown-number{
        font-family: 'Times New Roman';
        font-size: 3vw;
        font-weight: bold;
        margin:0px;
        padding:0px;
    }
    .countdown-text{
        font-family: 'Times New Roman';
        font-size: 2vw;
        margin:0px;
        padding:0px;
    }
} */

